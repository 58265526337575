import React, { useEffect } from "react";
import { Button, Modal, Space } from "antd";
import { Carousel } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { DataTable } from "./DataTable";
import img from "../asset/img/portfolio/portfolio-4.jpg"

export const ModalComponent = ({ setOpen, open, data }) => {
  const [image, setImage] = React.useState([]);
  
  useEffect(() => {
    data.imagename &&  showImage();
  }, [data]);

  const showImage = async() => {
    setImage([]);
    try {
      const response =await import(`../asset/img/items/${data.imagename}`);
      setImage((prev) => [...prev, response.default]);
    } catch (err) {
      console.log(err);
    }
  }

  const navigate = useNavigate();
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const contentStyle = {
    height: "160px",
    color: "#fff",
    textAlign: "center",
    background: "#364d79",
    display: "flex", // Add this line
    alignContent: "center", // Add this line
  };

  const handleContact = () => {
    setOpen(false);
  };

  const handleReadMore = () => {
    console.log(data.key);
    navigate(`/product/${data.key}`);
  };

  return (
    <>
      <Modal
        open={open}
        title={data.label}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={(_, { OkBtn, CancelBtn }) => (
          <div className="d-flex justify-content-between ">
            <div>
              <Button onClick={handleReadMore}>Read More</Button>
            </div>
            <div className="d-flex gap-2">
              <CancelBtn />
              {/* <OkBtn /> */}
              <Link to="https://wa.me/94711528478" target="_blank">
                {/* <Link to="/contact"> ; */}
                <Button onClick={() => handleContact()}>Contact Us</Button>
              </Link>
            </div>
          </div>
        )}
      >
        <div className="row">
          <div className="col-md-7">
            <div>
              <div className="mb-2">
                {/* <Carousel autoplay style={{ width: "100%" }}>
                  {image &&
                    image.map((img, index) => {
                      return (
                        <div
                          key={index}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img src={img} alt="img" style={{ width: "100%" }} />
                        </div>
                      );
                    })}
                </Carousel> */}
                <img src={image} alt="img" style={{ width: "100%" }} />
              </div>
              <h4>{data.title}</h4>
              <p style={{ textAlign: "justify" }}>{data.description}</p>
            </div>
          </div>
          <div className="col-md-5">
            <DataTable data={data} />
          </div>
        </div>
      </Modal>
    </>
  );
};
