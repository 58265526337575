import React from "react";
import { AiFillProduct } from "react-icons/ai";
import {
  MdMiscellaneousServices,
  MdOutlineConnectWithoutContact,
} from "react-icons/md";
import { Link } from "react-router-dom";

const Schedule = ({ myRef }) => {
  return (
    <section className="schedule mt-3">
      <div className="container">
        <div className="">
          <div className="">
            <div className="section-title">
              <h2>We Are Always Ready to Help You</h2>
              <p>
                Discover premium quality grinding machines, machinery parts, and
                expert repair services designed to meet your needs.
              </p>
            </div>
          </div>
        </div>
        <div className="schedule-inner">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12 h-100">
              <div className="single-schedule first">
                <div className="inner">
                  <div className="icon">
                    <i>
                      <AiFillProduct />
                    </i>
                  </div>
                  <div className="single-content">
                  <div className="d-flex align-items-center flex-column w-100">
                    <span>
                      <i>Explore Our</i>
                    </span>
                    <span >
                      <i className="font-weight-bold" style={{fontSize:"1.3rem",fontWeight:"bold"}}>Main Products</i>
                    </span>
                    {/* <h4>Main Products</h4> */}
                    </div>
                    <p>
                      From advanced grinding machines to essential machinery
                      parts, we offer a diverse range of products to enhance
                      your operations and boost productivity.
                    </p>
                    <Link to="/product">
                      See All Products<i className="fa fa-long-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 h-100">
              <div className="single-schedule middle">
                <div className="inner">
                  <div className="icon">
                    <i>
                      <MdMiscellaneousServices />
                    </i>
                  </div>
                  <div className="single-content">
                  <div className="d-flex align-items-center flex-column w-100">
                    <span><i>Discover</i></span>
                    <span >
                      <i className="font-weight-bold" style={{fontSize:"1.3rem",fontWeight:"bold"}}>Our Main Services</i>
                    </span>
                    </div>
                    {/* <h4>Our Main Services</h4> */}
                    <p>
                      We provide top-notch repair services and maintenance for
                      all types of machinery. Our team is dedicated to ensuring
                      optimal performance and longevity of your equipment.
                    </p>
                    <Link onClick={() => myRef.current.scrollIntoView()}>
                      Main Services<i className="fa fa-long-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-12 h-100">
              <div className="single-schedule last">
                <div className="inner">
                  <div className="icon">
                    <i>
                      <MdOutlineConnectWithoutContact />
                    </i>
                  </div>
                  <div className="single-content">
                  <div className="d-flex align-items-center flex-column w-100">
                    <span><i>Get in Touch</i></span>
                    <span >
                      <i className="font-weight-bold" style={{fontSize:"1.3rem",fontWeight:"bold"}}>Contact Us</i>
                    </span>
                    </div>
                    {/* <h4>Contact Us</h4> */}
                    <p>
                      Our dedicated support team is available to assist you with
                      any inquiries or issues you may have. We are committed to
                      providing timely and effective solutions to ensure your
                      satisfaction.
                    </p>
                    <Link to="/contact">
                      Contact Us<i className="fa fa-long-arrow-right"></i>
                    </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </section>
  );
};

export default Schedule;
