import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { DetailedSlider } from "../component/detailedPage/DetailedSlider";
import { products } from "../data/productData";
import { useState } from "react";
import { DataTable } from "../component/DataTable";
import { TabView } from "../component/detailedPage/TabView";

export const DetailedItem = () => {
  const param = useParams();
  const [productData, setProductData] = useState({});

  useEffect(() => {
    setProductData(products.find((item) => item.key === parseInt(param.id)));
  }, [param]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-7">
          <div className="mb-5">
            <DetailedSlider productData={productData}/>
          </div>
          <div className="">
            <h3>{productData?.title}</h3>
            <p style={{textAlign:"justify"}}>{productData?.description}</p>
            <TabView productData={productData}/>
          </div>
        </div>
        <div className="col-md-5">
            <DataTable data={productData} />
        </div>
      </div>
    </div>
  );
};
