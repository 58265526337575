import React, { useEffect } from "react";
import { HashRouter, Routes, Route, useLocation } from "react-router-dom";
import "./asset/css/style.css";
import "./asset/css/owl-carousel.css";
import "./asset/css/icofont.css";
import "./asset/css/normalize.css";
import "./asset/css/slicknav.min.css";
import "./asset/css/responsive.css";
import Navbar from "./component/Navbar.js";
import Footer from "./component/Footer.js";
import Contact from "./pages/Contact.js";
import About from "./pages/About.js";
import { Category } from "./pages/Category.js";
import Notfound from "./pages/Notfound.js";
import { Home } from "./pages/Home.js";
import { WhatsAppBtn } from "./component/WhatsAppBtn.jsx";
import ScrollToTopButton from "./component/ScrollToTopBtn.jsx";
import "@fortawesome/fontawesome-free/css/all.css";
import { FloatingDock } from "./component/FloatingDock.jsx";
import { DetailedItem } from "./pages/DetailedItem.jsx";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PreLoader } from "./component/Preloader.js";
import Foundry from "./pages/Foundry.js";

function AppContent() {
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2500); // Simulate a loading delay

    return () => clearTimeout(timer);
  }, [location]);
  const data0 = `
  888888ba           oo dP       dP 
  88     8b             88       88 
  88aaaa8P' dP    dP dP 88 .d888b88 
  88    8b. 88    88 88 88 88    88 
  88    .88 88.  .88 88 88 88.  .88 
  88888888P  88888P  dP dP  88888P8 
 `;
 
 const data1 = `
 d8888888P                            
     .d8'                            
    .d8'   .d8888b. 88d888b. .d8888b. 
  .d8'     88'   88 88'   88 88ooood8 
 d8'       88.  .88 88    88 88.  ... 
 Y8888888P  88888P' dP    dP  88888P'
 `;
 
 const data2 = `
           d888888P d888888P 
              88       88    
              88       88    
              88       88    
              88       88    
           d888888P    dP   
 `;
 
 const massage=`Welcome to BuildZone IT`;

 const conditionalConsoleLog = (message, color) => {
  const shouldShowLogs = true; // Set this flag based on your condition

  if (shouldShowLogs) {
    console.log(`%c${message}`, `color: ${color};`);
  }
};

const fetchdata = async() => {
  conditionalConsoleLog(data0, 'red');
  conditionalConsoleLog(data1, 'green');
  conditionalConsoleLog(data2, 'blue');
}

useEffect(() => {
  fetchdata();
  console.error = function() {};
  console.warn = function() {};
  console.assert = function() {};
  console.info = function() {};
  console.debug = function() {};
  console.info = function() {};
  
  
}, []);




  return (
    <>
      {loading && <PreLoader />}
      <div className="">
        <Navbar />
      </div>
      <div className="min-vh-100 py-4">
        <div className="mt-5">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/product" element={<Category />} />
            <Route path="/product/:id" element={<DetailedItem />} />
            <Route path="/foundry" element={<Foundry/>}/>
            <Route path="*" element={<Home />} />

          </Routes>
        </div>
      </div>
      {/* <WhatsAppBtn /> */}
      <FloatingDock />
      <ScrollToTopButton />
      <Footer />
    </>
  );
}

function App() {
  return (
    <HashRouter>
      <AppContent />
    </HashRouter>
  );
}

export default App;
