import React from "react";
import Iframe from "react-iframe";

const Location = ()=>{
    return(
        <div>
    
  
        <section className="contact_area section_gap" >
         {/* <Grow direction="down" in={inView} timeout={parseInt(import.meta.env.VITE_APP_ANIMATION_SPEED)}> */}
          <div className="container">
            <Iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.9502761220765!2d79.97033797577177!3d6.89655109310266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25784cf7e9c79%3A0xb4d914d4f769f2a1!2sJayantha%20Engineering%20Works!5e0!3m2!1sen!2slk!4v1707930646307!5m2!1sen!2slk"
              frameBorder="0"
              width="100%"
              height="600px"
              allow="fullscreen"
            />
            
            {/* <iframe src="" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            <br /> <br /> 
          </div>
          {/* </Grow> */}
        </section>
      </div>
    )
}

export default Location;