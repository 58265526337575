import React from "react";
import { Carousel } from "antd";
import Slider from "react-slick";
import img1 from "../asset/img/banner/1.png";
import img2 from "../asset/img/banner/2.png";
import img3 from "../asset/img/banner/foundryBanner.png";
import img4 from "../asset/img/banner/repairBanner.png";
import "./slider.css";
import { useMediaQuery } from "react-responsive";

const BannerSlider = () => {
  var settings = {
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <section className="slider mb-5">
      <div className="hero-slider" style={{ backgroundColor: "#2D2F7C" }}>
        <Slider {...settings}>
          <div
            className="d-flex justify-content-center"
            style={{ overflow: "hidden", maxHeight: "85vh" }}
          >
            <img
              src={img1}
              alt="img1"
              style={{
                width: "auto",
                height: isTabletOrMobile ? "25vh" : "85vh",
                aspectRatio: "initial",
              }}
            />
          </div>
          <div
            className="d-flex justify-content-center"
            style={{ overflow: "hidden", maxHeight: "85vh" }}
          >
            <img
              src={img2}
              alt="img2"
              style={{
                width: "auto",
                height: isTabletOrMobile ? "25vh" : "85vh",
                aspectRatio: "initial",
              }}
            />
          </div>
          <div
            className="d-flex justify-content-center"
            style={{ overflow: "hidden", maxHeight: "85vh" }}
          >
            <img
              src={img3}
              alt="img3"
              style={{
                width: "auto",
                height: isTabletOrMobile ? "25vh" : "85vh",
                aspectRatio: "initial",
              }}
            />
          </div>
          <div
            className="d-flex justify-content-center"
            style={{ overflow: "hidden", maxHeight: "85vh" }}
          >
            <img
              src={img4}
              alt="img4"
              style={{
                width: "auto",
                height: isTabletOrMobile ? "25vh" : "85vh",
                aspectRatio: "initial",
              }}
            />
          </div>

          {/* <div>
        <img src={img3} alt="img3" style={{width:"100%",maxHeight:"a75vh>
      </div> */}
        </Slider>
      </div>
    </section>
  );
};

export default BannerSlider;
