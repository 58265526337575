import React, { useState } from "react";
import Slider from "react-slick";
import { Thumbnail } from "./Thumbnail";
import { products } from "../data/productData";
import { ModalComponent } from "./Modal";

const MostPopular = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);

  const handleClick = async (value) => {
    setData(value);
    setOpen(true);
  };

  var settings = {
    autoplay:true,
    dots: true,
    infinite: true,
    speed: 750,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="Feautes section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <h2>Our Most Popular Products</h2>
              {/* <img src="img/" alt="#abc"/> */}
              <p>
                Discover our range of high-quality products designed to meet
                your industrial and commercial needs. Each solution is crafted
                with precision and care to ensure top performance and
                reliability.
              </p>
            </div>
          </div>
        </div>
        <div className="slider-container container ">
          <Slider {...settings}>
            {products
              .filter((item) => item.popular)
              .map((item,index) => (
                <div>
                  <div
                    key={index}
                    className={`portfolio-item mx-2 shadow`}
                    onClick={() => handleClick(item)}
                  >
                    <div className="portfolio-wrap">
                      <Thumbnail
                        topic={item.title}
                        imagename={item.imagename}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
      <ModalComponent setOpen={setOpen} open={open} data={data} />
    </section>
  );
};

export default MostPopular;
