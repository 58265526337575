import React, { useEffect, useState } from "react";
import { Thumbnail } from "./Thumbnail";
import { ModalComponent } from "./Modal";
import { products } from "../data/productData";
import { Table } from "antd";
import { parts } from "../data/parts";
import { useMediaQuery } from "react-responsive";

const ProductCategory = ({ activeItem, activeLabel }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const handleClick = async (value) => {
    setData(value);
    setOpen(true);
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (text, record) => (
        <img
          src={require(`../asset/img/parts/${record.image}`)}
          alt={record.label}
          style={{ width: "auto", height: isTabletOrMobile ? "auto" : "150px" }}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => text.map((item, index) => <h5 key={index}>{item}</h5>),
    },
  ];

  return (
    <div>
      <section id="portfolio" className="portfolio section-bg">
        <div className="container">
          <h2>{activeLabel}</h2>
          <div
            className="row portfolio-container"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            {products.map((value, index) => {
              return (
                <div
                  key={index}
                  className={`col-lg-4 col-md-6 portfolio-item ${
                    activeItem === value.category || activeItem === "-1"
                      ? ""
                      : "d-none"
                  }`}
                  onClick={() => handleClick(value)}
                >
                  <div className="portfolio-wrap">
                    <Thumbnail
                      topic={value.label}
                      imagename={value.imagename}
                    />
                  </div>
                </div>
              );
            })}
            {activeLabel === "Machinery Parts" && (
              <Table dataSource={parts} columns={columns} />
            )}
          </div>
        </div>
      </section>
      <ModalComponent setOpen={setOpen} open={open} data={data} />
    </div>
  );
};

export default ProductCategory;
