import ContactForm from "../component/ContactForm";
import Location from "../component/Location";

const Contact = () => {
  return (
    <>
      <Location />
      <ContactForm />
    </>
  );
};

export default Contact;
