import { Button } from "antd";
import React from "react";
import { GiChiliPepper, GiGrain, GiHexagonalNut } from "react-icons/gi";
import { IoFastFoodSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const MainProducts = () => {
  const navigate = useNavigate();

  const handleClickAllProducts = () => {
    navigate("/product");
  };
  return (
    <section className="Feautes section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <h2>Our Main Products</h2>
              {/* <img src="img/" alt="#abc"/> */}
              <p>
                Discover our range of high-quality products designed to meet
                your industrial and commercial needs. Each solution is crafted
                with precision and care to ensure top performance and
                reliability.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-12">
            <div className="single-features">
              <div className="signle-icon">
                {/* <i className="icofont-help-robot"></i> */}
                <div className="d-flex align-items-center justify-content-center">
                  <GiChiliPepper />
                </div>
              </div>
              <h3>Spice Grinding Mill Solutions</h3>
              <p>
                Our spice grinding mills are designed to handle a variety of
                spices with efficiency and precision. From coarse to fine
                grinding, these mills offer consistent results and are built for
                durability, making them ideal for industrial applications.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-12">
            <div className="single-features">
              <div className="signle-icon">
                <div className="d-flex align-items-center justify-content-center">
                  <GiGrain />
                </div>
              </div>
              <h3>Flour Grinding Mill Solutions</h3>
              <p>
                Our flour grinding mills provide high-quality flour with minimal
                waste. Designed for both small-scale and large-scale operations,
                these mills ensure optimal performance and product consistency,
                supporting various types of grains.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-12">
            <div className="single-features ">
              <div className="signle-icon">
                <div className="d-flex align-items-center justify-content-center">
                  <IoFastFoodSharp />
                </div>
              </div>
              <h3>Food Processors</h3>
              <p>
                Our food processors are engineered to simplify food preparation
                with versatile functions. Whether you need chopping, slicing, or
                blending, these processors deliver high efficiency and superior
                results for any kitchen environment.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-12">
            <div className="single-features last">
              <div className="signle-icon">
                <div className="d-flex align-items-center justify-content-center">
                  <GiHexagonalNut />
                </div>
              </div>
              <h3>Machinery Parts</h3>
              <p>
                We offer a comprehensive range of machinery parts designed for
                durability and performance. Our parts are compatible with
                various machines and are manufactured to meet industry
                standards, ensuring reliable operation and minimal downtime.
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              size="large"
              type="primary"
              onClick={handleClickAllProducts}
            >
              See All Products
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainProducts;
