export const parts = [
    {
        key: 0,
        name: ["HS Connecter Set"],
        image: "1.png",
    },
    {
        key: 1,
        name: ["HS Rubber Roller Coupling"],
        image: "2.png",
    },
    {
        key: 2,
        name: ["Qulle Pulley"],
        image: "3.png",
    },
    {
        key: 3,
        name: ["1508 Housing","1509 Housing","1511 Housing","1515 Housing","1517 Housing"],
        image: "4.png",
    },
    {
        key: 4,
        name: ["Oil Nut Large"],
        image: "5.png",
    },
    {
        key: 5,
        name: ["FFC 15 Tooth Disk","FFC 23 Tooth Disk","FFC 37 Tooth Disk","FFC 45 Tooth Disk"],
        image: "6.png",
    },
    {
        key: 6,
        name: ["Screen"],
        image: "7.png",
    },
    {
        key: 7,
        name: ["Jet Polisher Inlet Bush", "Jet Polisher Outlet Bush"],
        image: "8.png",
    },
    {
        key: 8,
        name: ["HS Fan"],
        image: "9.png",
    },
    {
        key: 9,
        name: ["Qulley Fan"],
        image: "10.png",
    },
    {
        key: 10,
        name: ["7\" Plate Pair", "10\" Plate Pair", "12\" Plate Pair"],
        image: "11.png",
    },
    {
        key: 11,
        name: ["10\" Disk Mill Pulley", "12\" Disk Mill Pulley"],
        image: "12.png",
    },
    {
        key: 12,
        name: ["Quly Connecter Set"],
        image: "13.png",
    },
    {
        key: 13,
        name: ["No7 Huller Shaft", "No3 Huller Shaft"],
        image: "14.png",
    },
    {
        key: 14,
        name: [
            "8.5 Cylinder/Warm",
            "Nakashima Cylinder",
            "N70 Cylinder/Warm",
            "N120 Cylinder/Warm",
            "Jet Cylinder/Warm",
            "Jet 2A Cylinder/Warm"
        ],
        image: "15.png",
    }
];
