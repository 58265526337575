import React from "react";
import { Tabs } from "antd";
import { AppleOutlined, AndroidOutlined } from "@ant-design/icons";

export const TabView = ({ productData }) => {
  const tabs = [
    // {
    //   key: 0,
    //   label: "Description",
    //   icon: <AppleOutlined />,
    //   children: <p style={{textAlign:"justify"}}>{productData.bigDescription}</p>

    // },
    // {
    //   key: 1,
    //   label: "Specifications",
    //   icon: <AndroidOutlined />,
    //   children: <p>Test content</p>,
    // },
    {
      key: 2,
      label: "More Information",
      icon: <AndroidOutlined />,
      children: (
        <ul style={{ listStyleType: "none", padding: "0" }}>
          {productData.instruction &&
            productData.instruction.map((item, i) => (
              <li key={i} style={{ marginBottom: "8px" }}>
                <span style={{ marginRight: "8px" }}>•</span> {item}
              </li>
            ))}
        </ul>
      ),
    },
  ];
  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        items={tabs.map((item, i) => {
          const id = String(i + 1);
          return {
            key: id,
            label: item.label,
            children: item.children,
            icon: item.icon,
          };
        })}
      />
    </div>
  );
};
