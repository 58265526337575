import React, { useState, useEffect } from "react";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {isVisible && (
        <div className="" style={
            {
                position: "fixed",
                bottom: "100px",
                right: "45px",
                zIndex: "99",
                backgroundColor: "#2D2F7C",
                // color: "#444",
                // cursor: "pointer",
                // width: "50px",
                // height: "50px",
                // borderRadius: "50%",
                // textAlign: "center",
                // fontSize: "30px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
                transition: "0.3s",
                borderRadius: "8px",
            }
        }>
        <a
          id="back-to-top"
          href="#"
          className="btn "
          role="button"
        >
          <i className="fas fa-chevron-up text-white"></i>
        </a>
        </div>
      )}
    </>
  );
};

export default ScrollToTopButton;
