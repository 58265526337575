import React from 'react';
import img1 from '../asset/img/about/output-onlinepngtools (2).png';
import img2 from '../asset/img/about/output-onlinepngtools (1).png';
import img3 from '../asset/img/about/company_logo-removebg-preview.png';
import './About.css';  // Make sure to import the CSS file

const About = () => {
    return (
        <div className="container">
            <div className="row mx-auto mt-4 align-items-center">
                <div className="col-md-6">
                    <div className="about-content align-items-center">
                        <h2 className="title">Who We Are</h2>
                        <p className="about-text quote">
                        Since 1987, our company has been a pioneer in the design and manufacture of premium spices and flour grinding mills. Combining advanced technology with traditional craftsmanship, we deliver robust, high-performance mills that meet the diverse needs of our global clientele. Our commitment to quality and innovation ensures that every product we produce enhances the flavour and texture of your spices and grains, making us a trusted partner in the culinary industry.
                        </p>
                    </div>
                </div>
                <div className="col-md-6 d-flex justify-content-center">
                    <img className="img-fluid m-3" src={img3} alt="img" style={{ borderRadius: '20px' , borderBottomRightRadius:"20px" ,height: "400px"}} />
                </div>
            </div>
            <div className="row mx-auto align-items-center">
                <div className="col-md-6 d-flex justify-content-center">
                    <img className="img-fluid m-3" src={img1} alt="img" style={{ borderBottomRightRadius:"20px" ,height: "400px"}} />
                </div>
                <div className="col-md-6">
                    <div className="about-content align-items-center">
                        <h2 className="title">Company Vision</h2>
                        <p className="about-text quote">
                        Vision of the company is to become a pioneer industry in Sri Lanka in quality reliability and trustworthy in manufacturing of machinery for mega industries while remaining environmentally friendly.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row mx-auto mt-4 align-items-center">
                <div className="col-md-6">
                    <div className="about-content">
                        <h2 className="title">Company Mission</h2>
                        <p className="about-text quote">
                        The mission is to provide the best quality and improved machinery products to grow their market in an efficient and effective manner.
                        </p>
                    </div>
                </div>
                <div className="col-md-6 d-flex justify-content-center">
                    <img className="img-fluid m-3" src={img2} alt="img" style={{ borderBottomRightRadius:"20px" ,height: "400px"}} />
                </div>
            </div>
            
        </div>
    );
};

export default About;
