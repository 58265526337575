import React from "react";
import foundryBanner from "../asset/img/foundry/foundry.png";
import "./About.css";
import { GiAutoRepair, GiRobotGolem } from "react-icons/gi";
import { BsGear, BsGearFill, BsShop } from "react-icons/bs";
import whiteCastIronImg from "../asset/img/foundry/white cast iron.jpeg";
import grayCastIronImg from "../asset/img/foundry/gray cast iron.jpg";
import { Thumbnail } from "../component/Thumbnail";

const Foundry = () => {
  return (
    <div className="container d-flex flex-column align-items-center">
      <div className="my-2">
        <img src={foundryBanner} alt="foundry_banner" />
      </div>
      <div>
        <br />
        <p className="text-center quote">
          Welcome to JIW Foundry Works, where we specialize in producing
          high-quality gray and white cast iron castings. Our expertise
          encompasses every stage of the process, from metal melting and molding
          to precise finishing. We are dedicated to delivering top-quality
          results that meet your exact specifications, ensuring durability and
          excellence in every casting we produce.
        </p>
      </div>
      <div className="row Feautes d-flex justify-content-center">
        <div className="col-lg-4 col-12">
          <div className="single-features">
            <div className="signle-icon">
              <div className="d-flex align-items-center justify-content-center">
                <BsGear />
              </div>
            </div>
            <h3>White Cast Iron</h3>
            <p>
              We specialize in the precision melting and molding of White Cast
              Iron to produce high-quality, durable machinery parts.
            </p>
            {/* <img src={whiteCastIronImg} alt="white_cast_iron"  style={{height:"200px"}}/> */}
            <div style={{borderTopRightRadius: "25px", overflow:"hidden"}}>
            <Thumbnail topic="" imagename="white cast iron.png" />
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-12">
          <div className="single-features last">
            <div className="signle-icon">
              <div className="d-flex align-items-center justify-content-center">
                <BsGearFill />
              </div>
            </div>
            <h3>Gray Cast Iron</h3>
            <p>
              We excel in the melting and molding of Gray Cast Iron to produce
              precision-engineered industrial machinery parts, manholes, and
              more.
            </p>
            {/* <img src={grayCastIronImg} alt="gray_cast_iron" style={{height:"200px"}} /> */}
            <div style={{borderTopRightRadius: "25px", overflow:"hidden"}}>
            <Thumbnail topic="" imagename="gray cast iron.jpg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Foundry;
