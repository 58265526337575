import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [capValue, setCapValue] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();

    if (!capValue) {
      alert("Please complete the ReCAPTCHA");
      return;
    }

    const templateParams = {
      from_name: name,
      from_email: email,
      subject: subject,
      message: message,
    };


    emailjs
      .send(
        "service_2dldry9", // Your EmailJS service ID
        "template_m9i9xzb", // Your EmailJS template ID
        templateParams,
        "7bbGGyjkZvJlfkXpz" // Your EmailJS public key
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };

  return (
    <div>
      <section className="contact_area section_gap">
        <div className="container">
          <div className="row contact">
            <div className="col-md-3">
              <div className="">
                <div className="info_item">
                  <i className="lnr lnr-home"></i>
                  <h6>2/C, Kahanthota Road,</h6>
                  <h6>Malambe,</h6>
                  <h6> Sri Lanka.</h6>
                </div>
                <div className="info_item">
                  <i className="lnr lnr-phone-handset"></i>
                  <h6>
                    <a>+94 112 412 680</a> (Landline)
                  </h6>
                  <h6>
                    <a>+94 712 739 220</a> (Propritor)
                  </h6>
                  <h6>
                    <a>+94 711 528 478</a> (Manager)
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <form className="row contact_form" onSubmit={handleClick}>
                <div className="col-md-6 ">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control mb-3"
                      id="name"
                      name="name"
                      placeholder="Enter your name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control mb-3"
                      id="email"
                      name="email"
                      placeholder="Enter your email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control mb-3"
                      id="subject"
                      name="subject"
                      placeholder="Enter Subject"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <textarea
                      className="form-control mb-3"
                      name="message"
                      id="message"
                      rows="5"
                      placeholder="Enter Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                    <div className="col-md-12 text-right d-flex justify-content-end contact-form">
                      <ReCAPTCHA
                        sitekey="6LfpGYQpAAAAAEGT-T-r61lEaOoNUKM9AAXpkSEC"
                        onChange={(value) => setCapValue(value)}
                      />
                      <button
                        type="submit"
                        className="btn ml-4"
                        disabled={
                          !name || !email || !subject || !message || !capValue
                        }
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactForm;