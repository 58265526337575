import React, { useEffect } from "react";
import { Table } from "antd";
import { useState } from "react";

export const DataTable = ({ data }) => {
  const [dataSource, setDataSource] = useState();
  const columns = [
    {
      title: "Feature",
      dataIndex: "label",
      key: "label",
      render: (text, record) => <strong>{text}</strong>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text, record) => <i>{text}</i>,
    },
  ];

  useEffect(() => {
    console.log(data);
    setDataSource(
      data?.specifications?.map((item, index) => ({
        key: index,
        label: item?.label,
        description: item?.description,
      }))
    );
  }, [data]);

  return <Table pagination={false} columns={columns} dataSource={dataSource} />;
};
