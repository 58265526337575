import React from "react";
import { Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import {
  ApartmentOutlined,
  UnorderedListOutlined ,
  ExperimentOutlined,
  TruckOutlined,
  ChromeOutlined,
  SettingOutlined,
  KubernetesOutlined,


} from "@ant-design/icons";
import ProductCategory from "../component/ProductCategory";
// import { get } from "jquery";

export const Category = () => {
  const [activeItem, setActiveitem] = React.useState("-1");
  const [activeLabel, setActiveLabel] = React.useState("All Products");

  const getCategoryLabelByKey = async(key) => {
    
    const categoryItem = categoryItems.find(item => item.key === key);
    // console.log(categoryItem);
    return categoryItem.label;
  };
  
  const handleItemClick = async(item) => {
   setActiveLabel(await getCategoryLabelByKey(parseInt(item.key))); 
    setActiveitem(item.key);    
  };

  const categoryItems = [
    {
      key: -1,
      icon: <UnorderedListOutlined />,
      label: "All Products",
    },
    {
      key: 0,
      icon: <ApartmentOutlined />,
      label: "Grinding Machines",
      children: [
        {
          key: 0.1,
          label: "Spices Grinding Machines",
        },
        {
          key: 0.2,
          label: "Flour Grinding Machines",
        },
      ],
    },
    // {
    //   key: 1,
    //   icon: <ExperimentOutlined />,
    //   label: "Oil Expellers",
    // },
    {
      key: 2,
      icon: <TruckOutlined />,
      label: "Food Processing",
    },
    // {
    //   key: 3,
    //   icon: <ChromeOutlined />,
    //   label: "Motors",
    // },
    {
      key: 4,
      icon: <SettingOutlined />,
      label: "Machinery Parts",
    },
    // {
    //   key: 5,
    //   icon: <KubernetesOutlined />,
    //   label: "Foundry",
    // }
  ];

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <>
              <Sider
                style={{
                  background: "#fff",
                  borderInlineEnd: "0px solid #ffffff",
                }}
              >
                <Menu
                  mode="inline"
                  defaultSelectedKeys={["-1"]}
                  style={{
                    height: "100%",
                    borderInlineEnd: "0px solid #ffffff",
                  }}
                  items={categoryItems}
                  onClick={(item) => handleItemClick(item)}
                />
              </Sider>
            </>
          </div>
          <div className="col-lg-9 col-12">
            {" "}
            <ProductCategory activeItem={activeItem} activeLabel={activeLabel} categoryItems={categoryItems} />
          </div>
        </div>
      </div>
    </>
  );
};
