import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../asset/img/Logo.png";
import { useMediaQuery } from "react-responsive";
import { Button, Drawer } from "antd";

const Navbar = () => {
  const [active, setActive] = useState(0);
  const [nav, setNav] = useState(false);
  const navigate = useNavigate();
  const path = useLocation();

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    // const path = window.location.pathname;
    console.log(path)
    if (path.pathname === "/") {
      setActive("home");
    } else if (path.pathname === "/product") {
      setActive("categories");
    } else if (path.pathname === "/foundry") {
      setActive("foundry");
    } else if (path.pathname === "/about") {
      setActive("about");
    } else if (path.pathname === "/contact") {
      setActive("contact");
    }
  }, []);

  const handleLinkClick = (id, path) => {
    setActive(id);
    setNav(false);
    navigate(path);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <header className="header ">
        <div className="header-inner position-fixed right-0  ">
          <div className="container">
            <div className="inner">
              <div className="row">
                <div className="col-lg-3 col-6">
                  <div className="logo">
                    <Link to="/">
                      <img
                        src={logo}
                        alt="#"
                        style={{
                          height: isDesktopOrLaptop ? "54px" : "48px", // scaled by 1.2
                          width: "auto",
                        }}
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-9 col-6 d-flex align-content-center justify-content-end">
                  <div className="main-menu d-flex justify-content-end">
                    <div
                      className={
                        "mobile-nav " +
                        (isTabletOrMobile
                          ? "d-flex align-items-center h-100"
                          : "d-none")
                      }
                    >
                      <Button
                        onClick={() => setNav(!nav)}
                        className={nav ? "active" : ""}
                      >
                        <i className="fas fa-bars"></i>
                      </Button>
                    </div>
                    <Drawer
                      open={nav}
                      onClose={() => setNav(false)}
                      placement="right"
                    >
                      <nav
                        className={
                          "navigation " + (isTabletOrMobile ? "" : "d-none")
                        }
                      >
                        <ul className="nav menu d-flex flex-column">
                          <li className={active === "home" ? "active" : ""}>
                            <Link
                              id="home"

                              className={`mobile-nav-link ${active === "home" ? "active" : ""}`}
                              style={{ textDecoration: "none" }}

                              onClick={() => handleLinkClick("home", "/")}
                              to={"/"}
                            >
                              Home{" "}
                            </Link>
                          </li>
                          <li
                            className={active === "categories" ? "active" : ""}
                          >
                            <Link
                              id="categories"

                              className={`mobile-nav-link ${active === "categories" ? "active" : ""}`}
                              style={{ textDecoration: "none" }}
                              onClick={() => handleLinkClick("categories", "/product")}
                              to={"/product"}

                            >
                              Products
                            </Link>
                          </li>
                          <li className={active === "foundry" ? "active" : ""}>
                            <Link
                              id="foundry"

                              className={`mobile-nav-link ${active === "foundry" ? "active" : ""}`}
                              style={{ textDecoration: "none" }}

                              onClick={() => handleLinkClick("foundry", "/foundry")}
                              to={"/foundry"}
                            >
                              Foundry
                            </Link>
                          </li>
                          <li className={active === "about" ? "active" : ""}>
                            <Link
                              id="about"

                              className={`mobile-nav-link ${active === "about" ? "active" : ""}`}
                              style={{ textDecoration: "none" }}

                              onClick={() => handleLinkClick("about", "/about")}
                              to={"/about"}
                            >
                              About Us{" "}
                            </Link>
                          </li>

                          <li className={active === "contact" ? "active" : ""}>
                            <Link
                              id="contact"

                              className={`mobile-nav-link ${active === "contact" ? "active" : ""}`}
                              style={{ textDecoration: "none" }}

                              onClick={() => handleLinkClick("contact", "/contact")}
                              to={"/contact"}
                            >
                              Contact Us
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </Drawer>
                    <nav
                      className={
                        "navigation " + (!isTabletOrMobile ? "" : "d-none")
                      }
                    >
                      <ul className="nav menu">
                        <li className={active === "home" ? "active" : ""}>
                          <Link
                            id="home"
                            style={{ textDecoration: "none", fontSize: "1.2em" }} // scaled by 1.2
                            onClick={() => handleLinkClick("home", "/")}
                            to={"/"}
                          >
                            Home
                          </Link>
                        </li>
                        <li className={active === "categories" ? "active" : ""}>
                          <Link
                            id="categories"
                            style={{ textDecoration: "none", fontSize: "1.2em" }} // scaled by 1.2
                            onClick={() => handleLinkClick("categories", "/product")}
                            to={"/product"}
                          >
                            Products
                          </Link>
                        </li>
                        <li className={active === "foundry" ? "active" : ""}>
                          <Link
                            id="foundry"
                            style={{ textDecoration: "none", fontSize: "1.2em" }} // scaled by 1.2
                            onClick={() => handleLinkClick("foundry", "/foundry")}
                            to={"/foundry"}
                          >
                            Foundry
                          </Link>
                        </li>
                        <li className={active === "about" ? "active" : ""}>
                          <Link
                            id="about"
                            style={{ textDecoration: "none", fontSize: "1.2em" }} // scaled by 1.2
                            onClick={() => handleLinkClick("about", "/about")}
                            to={"/about"}
                          >
                            About Us{" "}
                          </Link>
                        </li>

                        <li className={active === "contact" ? "active" : ""}>
                          <Link
                            id="contact"
                            style={{ textDecoration: "none", fontSize: "1.2em" }} // scaled by 1.2
                            onClick={() => handleLinkClick("contact", "/contact")}
                            to={"/contact"}
                          >
                            Contact Us
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
