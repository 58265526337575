import React, { useRef } from "react";
import Feature from "../component/Feature.js";
import Shedule from "../component/Shedule.js";
import Sidebar from "../component/Slider.js";
import Services from "../component/Services.js";
import BannerSlider from "../component/Slider.js";
import MainProducts from "../component/MainProducts.jsx";
import MostPopular from "../component/MostPopular.jsx";

export const Home = () => {
  const myRef = useRef(null);
  return (
    <div>
      <BannerSlider />
      <Shedule myRef={myRef} />
      <div ref={myRef}>
        <Feature  />
      </div>
      <MainProducts />
      <MostPopular />
      <Services />
    </div>
  );
};
