import React from "react";
import "./Preloader.css";

export const PreLoader = () => {
  return (
    <div className="w-100 h-100 bg-white">
      <div className="preloader">
        <div className="preloader-image">
          <img src={require("../asset/img/loader2.gif")} />{" "}
        </div>{" "}
      </div>
    </div>
  );
};