import React, {useEffect} from "react";
import Slider from "react-slick";
import img from "../../asset/img/portfolio/portfolio-4.jpg";

export const DetailedSlider = ({
  productData,
}) => {
  
  const [images, setImages] = React.useState([]);
  useEffect(()=>{
  
    const fetchImage = async () => {
      setImages([]);

      try{
        const response = await import(`../../asset/img/items/${productData.imagename}`);
        setImages((prev) => [...prev, response.default]);
      }
      catch(err){
        console.log(err);
      }
      finally{
        // console.log("Image Loaded");
      }
      
    }
    fetchImage();
  },[productData])
  
  const settings = {
    customPaging:  (i) => {
      return (
        <div>
          <img src={images} />
        </div>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    // cssEase: "linear"
    appendDots: (dots) => (
      <div>
        <ul style={{ transform: "scale(3)", margin: 3, padding: 3 }}>{dots}</ul>
      </div>
    ),
  };
  return (

    <div className="slider-container ">
      {/* <Slider {...settings}>
        {images && images.map((img, index) => (
          <div className="d-flex justify-content-center" key={index}>
            <img src={img} alt="slider" />
          </div>
        ))}
      </Slider> */}
      {productData.imagename && <img src={images} alt="slider" />}
    </div>
  );
};
