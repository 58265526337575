import React, { useRef } from "react";
import { GiRobotGolem } from "react-icons/gi";
import { GiAutoRepair } from "react-icons/gi";
import { BsShop } from "react-icons/bs";

const Feature = ({ myRef }) => {
  return (
    <section className="Feautes section">
      <div className="container" ref={myRef}>
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <h2>Our Main Services</h2>
              {/* <img src="img/" alt="#abc"/> */}
              <p>
                We offer a range of services designed to meet your industrial
                needs, from creating customized automated machines to repairing
                and selling high-quality equipment. Our team of experts is
                committed to delivering exceptional service and support.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="single-features">
              <div className="signle-icon">
                <div className="d-flex align-items-center justify-content-center">
                  <GiRobotGolem />
                </div>
              </div>
              <h3>Create Automated Machines</h3>
              <p>
                Our team specializes in designing and manufacturing automated
                machines tailored to your specific operational needs. We
                leverage cutting-edge technology to create solutions that
                enhance efficiency, reduce manual labor, and improve
                productivity in your processes.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-12">
            <div className="single-features">
              <div className="signle-icon">
                <div className="d-flex align-items-center justify-content-center">
                  <GiAutoRepair />
                </div>
              </div>
              <h3>Machine Repairs</h3>
              <p>
                We offer comprehensive repair services for a wide range of
                machinery. Our skilled technicians are equipped to handle
                everything from minor adjustments to major overhauls, ensuring
                your equipment operates at peak performance and minimizing
                downtime.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-12">
            <div className="single-features last">
              <div className="signle-icon">
                <div className="d-flex align-items-center justify-content-center">
                  <BsShop />
                </div>
              </div>
              <h3>Machine Sales</h3>
              <p>
                We provide a diverse selection of high-quality machines for
                sale, catering to various industrial sectors. Our sales team
                works closely with you to understand your requirements and
                recommend the best equipment to meet your needs, ensuring
                excellent performance and reliability.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
